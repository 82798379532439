@use 'colors.scss';

#date {
    background: url(../resources/date-time/date_background.png);
    position: absolute;
    top: 680px;
    width: 115px;
    height: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .date-date {
        font-family: 'edit-undo';
        font-size: 19px;
        text-transform: lowercase;
        color: colors.$main-accent;
        @include colors.glow;
    }
}

#time {
    background: url(../resources/date-time/time_background.png);
    position: absolute;
    top: 710px;
    width: 56px;
    height: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .date-time {
        font-family: 'edit-undo';
        font-size: 19px;
        text-transform: lowercase;
        color: colors.$main-accent;
        @include colors.glow;
    }
}